<script>
import { Bar } from 'vue-chartjs'
import date from '../../../mixins/date'
export default {
  extends: Bar,
  mixins: [date],
  props: {
    mainData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      chartData: null,
      options: {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'Acceptance Percentage'
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    }
  },
  watch: {
    mainData () {
      this.getChart()
    }
  },
  mounted () {
    this.getChart()
  },
  methods: {
    async getChart () {
      await this.fillChart()
      await this.renderChart(this.chartData, this.options)
    },
    fillChart () {
      this.chartData = {
        labels: this.mainData.map(n => n.full_name),
        datasets: [
          {
            label: 'Change Request Acceptance',
            backgroundColor: '#99a3af',
            data: this.mainData.map(r => r.change_acceptance_percent)
          },
          {
            label: 'Assignment Acceptance',
            backgroundColor: '#4fb9de',
            data: this.mainData.map(r => r.assignment_acceptance_percent)
          }
        ]
      }
    }
  }
}
</script>
