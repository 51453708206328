<template>
  <div class="reports">
    <top-nav />
    <assignment-reports v-if="this.$route.name == 'assignment_reports'" />
  </div>
</template>

<script>
import TopNav from './TopNav'
import AssignmentReports from '../assignments/Report'

export default {
  components: {
    AssignmentReports,
    TopNav
  }
}
</script>

<style lang="scss">
.reports {
  .hlt-nav-container {
    margin-bottom: 0;
  }
}
</style>
