<script>
const SEC_PER_DAY = 86400
import { Bar } from 'vue-chartjs'
import date from '../../../mixins/date'
export default {
  extends: Bar,
  props: {
    mainData: {
      type: Array,
      required: true
    },
  },
  mixins: [ date ],
  watch: {
    mainData () {
      this.getChart()
    }
  },
  data() {
    return {
      chartData: null,
      options: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'Avg Days To Completion'
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    }
  },
  mounted () {
    this.getChart()
  },
  methods: {
    async getChart () {
      await this.fillChart()
      await this.renderChart(this.chartData, this.options)
    },
    dayCount(num) {
      return (num / SEC_PER_DAY).toFixed(2)
    },
    fillChart () {
      this.chartData = {
        labels: this.mainData.map(n => n.full_name),
        datasets: [
          {
            label: 'Avg Completion Days',
            backgroundColor: '#4fb9de',
            data: this.mainData.map(r => this.dayCount(r.avg_time_to_completion))
          }
        ]
      }
    }
  }
}
</script>
