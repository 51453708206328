<template>
  <div class="assignment-reports">
    <div v-if="assignmentReport && assignmentReport.meta" class="dashboard-block">
      <div class="flex-base-row">
        <div class="flex-item">
          <div class="metric-card">
            <span class="metric-title">Assignments</span>
            <h3 class="metric-number weight-700">{{ assignmentReport.meta.assignments_count }}</h3>
          </div>
        </div>
        <div class="flex-item">
          <div class="metric-card">
            <span class="metric-title">Copywriters</span>
            <h3 class="metric-number weight-700">{{ assignmentReport.meta.copywriters_count }}</h3>
          </div>
        </div>
        <div class="flex-item">
          <div class="metric-card">
            <span class="metric-title">Adjustments</span>
            <h3 class="metric-number weight-700">{{ assignmentReport.meta.change_requests_count }}</h3>
          </div>
        </div>
        <div class="flex-item">
          <div class="metric-card">
            <span class="metric-title">Overall Acceptance</span>
            <h3 class="metric-number weight-700">{{ Number(assignmentReport.meta.overall_acceptance_percent).toFixed(2) }}%</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-base-row">
      <div class="reporting-tabs-section flex-left">
        <ul class="reporting-tabs">
          <li :class="[{ active: activeTab === 'all' }, 'reporting-tab']">
            <a @click="selectDays(null, 'all')">All</a>
          </li>
          <li :class="[{ active: activeTab === 'thirty' }, 'reporting-tab']">
            <a @click="selectDays(30, 'thirty')">30 Days</a>
          </li>
          <li :class="[{ active: activeTab === 'sixty' }, 'reporting-tab']">
            <a @click="selectDays(60, 'sixty')">60 Days</a>
          </li>
          <li :class="[{ active: activeTab === 'ninety' }, 'reporting-tab']">
            <a @click="selectDays(90, 'ninety')">90 Days</a>
          </li>
        </ul>
      </div>
      <div class="filtering-select-section flex-right">
        <select v-model="selectedApp" class="app-filter hlt-select" @change="getAssignmentReport({ app_id: selectedApp, days: selectedDays})">
          <option value="">Select App</option>
          <option v-for="option in currentUser.apps" :value="option.id">
            {{ option.internal_name }}
          </option>
        </select>
      </div>
    </div>
    <div class="assignment-report-container">
      <div class="flex-base-row reporting-row">
        <div class="flex-left">
          <total-assignments v-if="assignmentReport && assignmentReport.records" :main-data.sync="assignmentReport.records" />
        </div>
        <div class="flex-right">
          <hourly-chart v-if="assignmentReport && assignmentReport.records" :main-data.sync="assignmentReport.records" />
        </div>
      </div>
      <div class="flex-base-row reporting-row">
        <div class="flex-left">
          <completion-time v-if="assignmentReport && assignmentReport.records" :main-data.sync="assignmentReport.records" />
        </div>
        <div class="flex-right">
          <acceptance-percentage v-if="assignmentReport && assignmentReport.records" :main-data.sync="assignmentReport.records" />
        </div>
      </div>
      <div class="flex-base-row reporting-row">
        <div class="flex-left">
          <rejection-cycle v-if="assignmentReport && assignmentReport.records" :main-data.sync="assignmentReport.records" />
        </div>
        <div class="flex-right">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HourlyChart from './Hourly'
import TotalAssignments from './TotalAssignments'
import CompletionTime from './CompletionTime'
import AcceptancePercentage from './AcceptancePercentage'
import RejectionCycle from './RejectionCycle'
export default {
  components: {
    HourlyChart,
    TotalAssignments,
    CompletionTime,
    AcceptancePercentage,
    RejectionCycle
  },
  computed: {
    ...mapGetters([
      'assignmentReport',
      'currentUser'
    ])
  },
  data () {
    return {
      selectedApp: null,
      selectedDays: null,
      activeTab: 'all'
    }
  },
  created () {
    this.getAssignmentReport({ app_id: this.selectedApp, days: this.selectedDays })
  },
  methods: {
    ...mapActions([
      'getAssignmentReport'
    ]),
    selectDays (selectDays, tab) {
      this.activeTab = tab
      this.selectedDays = selectDays
      this.getAssignmentReport({ app_id: this.selectedApp, days: this.selectedDays })
    }
  }
}
</script>

<style lang="scss">
.assignment-reports {
  padding: 15px 8rem;
  background: $smoke-gray;
  .dashboard-block {
    margin: 0 auto 40px;
  }
  .reporting-row {
    margin-bottom: 30px;
  }
  .metric-card {
    width: 200px;
    margin: 15px auto;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    justify-items: center;
    flex-direction: column;
    padding: 5px 15px;
    color: $space-black-10;
    background: $white-1;
    border-radius: 4px;
    border: 1px solid $space-gray-20;
    .metric-title {
      margin-top: 5px;
      font-size: .75rem;
      font-weight: 600;
      color: $space-gray-10;
    }
  }
  .reporting-tabs-section {
    .reporting-tabs {
      list-style-type: none;
      display: flex;
      flex-direction: row;
      margin: 18px 0;
      .reporting-tab {
        border: 1px solid $space-gray-10;
        padding: 6px 18px;
        background: $white-1;
        color: $slate-gray;
        display: block;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.03em;
        &:first-child {
          border-radius: 4px 0 0 4px;
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
        }
        &.active {
          background: $cold-gray;
          color: $white-1;
          border: 1px solid $cold-gray;
          a, span {
            color: $white-1;
          }
        }
        a {
          color: $slate-gray;
        }
        span {
          margin-left: 12px;
          color: $space-black-10;
        }
      }
    }
  }
  .filtering-select-section {
    align-self: center;
    select {
      width: 300px;
      margin: 0;
    }
  }
}
</style>
