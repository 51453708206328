<script>
import { Bar } from 'vue-chartjs'
export default {
  extends: Bar,
  props: {
    mainData: {
      type: Array,
      required: true
    }
  },
  watch: {
    mainData () {
      this.getChart()
    }
  },
  data() {
    return {
      chartData: null,
      options: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'Total Assignments'
        },
        scales: {
          xAxes: [{
              stacked: true
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            stacked: true
          }]
        }
      }
    }
  },
  mounted () {
    this.getChart()
  },
  methods: {
    async getChart () {
      await this.fillChart()
      await this.renderChart(this.chartData, this.options)
    },
    fillChart () {
      this.chartData = {
        labels: this.mainData.map(n => n.full_name),
        datasets: [
          {
            label: 'Assigned',
            backgroundColor: '#dadada',
            data: this.mainData.map(r => r.total_assigned)
          },
          {
            label: 'Submitted',
            backgroundColor: '#4fb9de',
            data: this.mainData.map(r => r.total_submitted)
          },
          {
            label: 'Rejected',
            backgroundColor: '#f85359',
            data: this.mainData.map(r => r.total_rejected)
          },
          {
            label: 'Completed',
            backgroundColor: '#7bd56e',
            data: this.mainData.map(r => r.total_completed)
          }
        ]
      }
    }
  }
}
</script>
